import React, { Component } from 'react';
import { graphql } from "gatsby";
import { THEMES } from "../context/withApp";
import Page from "../components/Page/Page";
import SEO from "../components/SEO/SEO";
import WorkCard from "../components/WorkCard/WorkCard";
import TeaserTitle from '../components/UI/Teaser/TeaserTitle';
import ScrollReveal from '../components/UI/ScrollReveal/ScrollReveal';
import styles from './work.module.scss';

class Work extends Component {
    render() {
        return (
            <Page { ...this.props.page } theme={ THEMES.dark }>
                
                <SEO title="Work" description="Selected digital design & front-end develpment work" keywords={[`digital`, `design`, `front-end development`, `freelance`, `portfolio`, `southampton`, `hampshire`]}/>

                <header className={styles.header}>
                    <ScrollReveal animation="blockWipe" delay={0}>
                        <TeaserTitle>Selected Works</TeaserTitle>
                    </ScrollReveal>
                    
                    <ScrollReveal animation="slideUp" delay={0.33}>
                        <h1 className={styles.heading}><span>Some highlights of work I've done for forward thinking brands.</span></h1>
                    </ScrollReveal>

                    {/* <ScrollReveal animation="slideUp" delay={1}>
                        <p className={styles.filter}>Showing you <a href="">everything</a>.</p>
                    </ScrollReveal> */}
                </header>
                    
                <div className={styles.itemContainer}>
                    { this.props.data.allWorkJson.edges.map( ({ node }, index) => 
                        <ScrollReveal animation={index === 0 ? '' : 'slideUp'} delay={0.33} key={node.title}>
                            <div className={styles.item}>
                                <WorkCard 
                                    title={`${node.title}`} 
                                    subtitle={`${node.subtitle}`} 
                                    description={node.description} 
                                    slug={node.fields.slug} 
                                    img={node.thumbSrc}
                                    imgMaxWidth={960}
                                    isFullWidth={false}/>
                            </div>
                        </ScrollReveal>
                    )}
                </div>

            </Page>
        );
    }
}

export const query = graphql`
    query {
        allWorkJson(filter: { isPublished: { eq: true }  }, sort: { order:  DESC, fields: [ publishDate ]}) {
            edges {
                node {
                    title
                    subtitle
                    description
                    thumbSrc {
                        childImageSharp {
                          fluid(maxWidth: 1920, toFormat: JPG, quality: 50, grayscale: true, srcSetBreakpoints: [ 400, 800, 1600, 2400 ]) {
                            ...GatsbyImageSharpFluid_noBase64
                          }
                        }
                    }
                    fields {
                        slug
                    }
                }
            }
        }
    }
`

export default Work;
